/* leny/leny.github.io
 *
 * /src/components/svg/webdeveloper.js - Profession SVG Component
 *
 * coded by leny@flatLand!
 * started at 23/02/2019
 */

import React from "react";

export default ({className, title, fill = "#000000"}) => (
    <svg
        className={className}
        viewBox={"0 0 64 22"}
        version={"1.1"}
        xmlns={"http://www.w3.org/2000/svg"}>
        <title>{title}</title>
        <g stroke={"none"} strokeWidth={"1"} fill={fill} fillRule={"evenodd"}>
            <path
                d={[
                    "M58.408,16.172",
                    "C58.1679988,16.4760015",
                    "57.8420021,16.7139991",
                    "57.43,16.886",
                    "C57.0179979,17.0580009",
                    "56.5960022,17.144",
                    "56.164,17.144",
                    "C55.755998,17.144",
                    "55.3780017,17.0740007",
                    "55.03,16.934",
                    "C54.6819983,16.7939993",
                    "54.3840012,16.5960013",
                    "54.136,16.34",
                    "C53.8879988,16.0839987",
                    "53.6940007,15.7780018",
                    "53.554,15.422",
                    "C53.4139993,15.0659982",
                    "53.344,14.6720022",
                    "53.344,14.24",
                    "C53.344,13.839998",
                    "53.4099993,13.4640018",
                    "53.542,13.112",
                    "C53.6740007,12.7599982",
                    "53.8559988,12.4520013",
                    "54.088,12.188",
                    "C54.3200012,11.9239987",
                    "54.5999984,11.7160008",
                    "54.928,11.564",
                    "C55.2560016,11.4119992",
                    "55.615998,11.336",
                    "56.008,11.336",
                    "C56.3840019,11.336",
                    "56.7339984,11.4019993",
                    "57.058,11.534",
                    "C57.3820016,11.6660007",
                    "57.6619988,11.8599987",
                    "57.898,12.116",
                    "C58.1340012,12.3720013",
                    "58.3179993,12.6899981",
                    "58.45,13.07",
                    "C58.5820007,13.4500019",
                    "58.648,13.8879975",
                    "58.648,14.384",
                    "L53.944,14.384",
                    "C53.952,14.6960016",
                    "54.0119994,14.9899986",
                    "54.124,15.266",
                    "C54.2360006,15.5420014",
                    "54.387999,15.781999",
                    "54.58,15.986",
                    "C54.772001,16.190001",
                    "55.0019987,16.3499994",
                    "55.27,16.466",
                    "C55.5380013,16.5820006",
                    "55.8359984,16.64",
                    "56.164,16.64",
                    "C56.3320008,16.64",
                    "56.5059991,16.6220002",
                    "56.686,16.586",
                    "C56.8660009,16.5499998",
                    "57.0399992,16.4960004",
                    "57.208,16.424",
                    "C57.3760008,16.3519996",
                    "57.5299993,16.2680005",
                    "57.67,16.172",
                    "C57.8100007,16.0759995",
                    "57.9279995,15.9680006",
                    "58.024,15.848",
                    "L58.408,16.172",
                    "L58.408,16.172",
                    "Z",
                    "M58.06,13.916",
                    "C57.9879996,13.2359966",
                    "57.7720018,12.7200018",
                    "57.412,12.368",
                    "C57.0519982,12.0159982",
                    "56.5840029,11.84",
                    "56.008,11.84",
                    "C55.7119985,11.84",
                    "55.4420012,11.8939995",
                    "55.198,12.002",
                    "C54.9539988,12.1100005",
                    "54.7440009,12.2579991",
                    "54.568,12.446",
                    "C54.3919991,12.6340009",
                    "54.2500005,12.8539987",
                    "54.142,13.106",
                    "C54.0339995,13.3580013",
                    "53.9720001,13.6279986",
                    "53.956,13.916",
                    "L58.06,13.916",
                    "Z",
                    "M43.48,12.644",
                    "L43.48,12.254",
                    "L43.48,11.966",
                    "C43.48,11.8819996",
                    "43.478,11.8020004",
                    "43.474,11.726",
                    "C43.47,11.6499996",
                    "43.464,11.5680004",
                    "43.456,11.48",
                    "L43.96,11.48",
                    "C43.968,11.5680004",
                    "43.974,11.6479996",
                    "43.978,11.72",
                    "C43.982,11.7920004",
                    "43.986,11.8699996",
                    "43.99,11.954",
                    "C43.994,12.0380004",
                    "43.996,12.1339995",
                    "43.996,12.242",
                    "L43.996,12.632",
                    "C44.0600003,12.5119994",
                    "44.1479994,12.3760008",
                    "44.26,12.224",
                    "C44.3720006,12.0719992",
                    "44.5119992,11.9300007",
                    "44.68,11.798",
                    "C44.8480008,11.6659993",
                    "45.0479988,11.5560004",
                    "45.28,11.468",
                    "C45.5120012,11.3799996",
                    "45.7799985,11.336",
                    "46.084,11.336",
                    "C46.6200027,11.336",
                    "47.0279986,11.4499989",
                    "47.308,11.678",
                    "C47.5880014,11.9060011",
                    "47.7839994,12.2279979",
                    "47.896,12.644",
                    "L47.896,12.632",
                    "C47.9520003,12.5199994",
                    "48.0419994,12.3900007",
                    "48.166,12.242",
                    "C48.2900006,12.0939993",
                    "48.4439991,11.9520007",
                    "48.628,11.816",
                    "C48.8120009,11.6799993",
                    "49.0239988,11.5660005",
                    "49.264,11.474",
                    "C49.5040012,11.3819995",
                    "49.7719985,11.336",
                    "50.068,11.336",
                    "C50.4360018,11.336",
                    "50.7459987,11.3919994",
                    "50.998,11.504",
                    "C51.2500013,11.6160006",
                    "51.4499993,11.7779989",
                    "51.598,11.99",
                    "C51.7460007,12.2020011",
                    "51.8519997,12.4579985",
                    "51.916,12.758",
                    "C51.9800003,13.0580015",
                    "52.012,13.3919982",
                    "52.012,13.76",
                    "L52.012,17",
                    "L51.472,17",
                    "L51.472,13.94",
                    "C51.472,13.531998",
                    "51.4400003,13.1920014",
                    "51.376,12.92",
                    "C51.3119997,12.6479986",
                    "51.2160006,12.4320008",
                    "51.088,12.272",
                    "C50.9599994,12.1119992",
                    "50.8060009,12.0000003",
                    "50.626,11.936",
                    "C50.4459991,11.8719997",
                    "50.2360012,11.84",
                    "49.996,11.84",
                    "C49.7239986,11.84",
                    "49.4680012,11.8959994",
                    "49.228,12.008",
                    "C48.9879988,12.1200006",
                    "48.7780009,12.2679991",
                    "48.598,12.452",
                    "C48.4179991,12.6360009",
                    "48.2760005,12.8459988",
                    "48.172,13.082",
                    "C48.0679995,13.3180012",
                    "48.016,13.5639987",
                    "48.016,13.82",
                    "L48.016,17",
                    "L47.476,17",
                    "L47.476,13.94",
                    "C47.476,13.531998",
                    "47.4440003,13.1920014",
                    "47.38,12.92",
                    "C47.3159997,12.6479986",
                    "47.2220006,12.4320008",
                    "47.098,12.272",
                    "C46.9739994,12.1119992",
                    "46.8220009,12.0000003",
                    "46.642,11.936",
                    "C46.4619991,11.8719997",
                    "46.2520012,11.84",
                    "46.012,11.84",
                    "C45.6839984,11.84",
                    "45.3960012,11.9119993",
                    "45.148,12.056",
                    "C44.8999988,12.2000007",
                    "44.6920008,12.3839989",
                    "44.524,12.608",
                    "C44.3559992,12.8320011",
                    "44.2300004,13.0799986",
                    "44.146,13.352",
                    "C44.0619996,13.6240014",
                    "44.02,13.8919987",
                    "44.02,14.156",
                    "L44.02,17",
                    "L43.48,17",
                    "L43.48,12.644",
                    "Z",
                    "M40.9,16.16",
                    "L41.716,16.16",
                    "L41.716,17",
                    "L40.9,17",
                    "L40.9,16.16",
                    "Z",
                    "M31.6,18.86",
                    "C31.733334,18.9666672",
                    "31.8799992,19.0699995",
                    "32.04,19.17",
                    "C32.2000008,19.2700005",
                    "32.3999988,19.32",
                    "32.64,19.32",
                    "C32.9600016,19.32",
                    "33.2233323,19.186668",
                    "33.43,18.92",
                    "C33.6366677,18.653332",
                    "33.8533322,18.2400028",
                    "34.08,17.68",
                    "L34.38,16.92",
                    "L30.16,7.62",
                    "L32.88,7.62",
                    "L35.56,14.04",
                    "L37.92,7.62",
                    "L40.5,7.62",
                    "L36.58,17",
                    "C36.3933324,17.466669",
                    "36.2066676,17.9466642",
                    "36.02,18.44",
                    "C35.8333324,18.9333358",
                    "35.6033347,19.3899979",
                    "35.33,19.81",
                    "C35.0566653,20.2300021",
                    "34.720002,20.573332",
                    "34.32,20.84",
                    "C33.919998,21.106668",
                    "33.4133364,21.24",
                    "32.8,21.24",
                    "C32.266664,21.24",
                    "31.8233351,21.1766673",
                    "31.47,21.05",
                    "C31.1166649,20.9233327",
                    "30.806668,20.7466678",
                    "30.54,20.52",
                    "L31.6,18.86",
                    "Z",
                    "M20.6,7.62",
                    "L22.86,7.62",
                    "L22.92,8.68",
                    "C23.0400006,8.546666",
                    "23.1833325,8.4066674",
                    "23.35,8.26",
                    "C23.5166675,8.1133326",
                    "23.7166655,7.9766673",
                    "23.95,7.85",
                    "C24.1833345,7.7233327",
                    "24.4466652,7.6200004",
                    "24.74,7.54",
                    "C25.0333348,7.4599996",
                    "25.3666648,7.42",
                    "25.74,7.42",
                    "C26.9133392,7.42",
                    "27.8066636,7.7699965",
                    "28.42,8.47",
                    "C29.0333364,9.1700035",
                    "29.34,10.1133274",
                    "29.34,11.3",
                    "L29.34,17",
                    "L26.98,17",
                    "L26.98,11.32",
                    "C26.98,10.719997",
                    "26.8166683,10.2566683",
                    "26.49,9.93",
                    "C26.1633317,9.6033317",
                    "25.7400026,9.44",
                    "25.22,9.44",
                    "C24.8999984,9.44",
                    "24.6000014,9.5033327",
                    "24.32,9.63",
                    "C24.0399986,9.7566673",
                    "23.800001,9.9333322",
                    "23.6,10.16",
                    "C23.399999,10.3866678",
                    "23.2433339,10.6499985",
                    "23.13,10.95",
                    "C23.0166661,11.2500015",
                    "22.96,11.5733316",
                    "22.96,11.92",
                    "L22.96,17",
                    "L20.6,17",
                    "L20.6,7.62",
                    "Z",
                    "M18.66,15.64",
                    "C18.6199998,15.6800002",
                    "18.5100009,15.7899991",
                    "18.33,15.97",
                    "C18.1499991,16.1500009",
                    "17.8900017,16.339999",
                    "17.55,16.54",
                    "C17.2099983,16.740001",
                    "16.7766693,16.9199992",
                    "16.25,17.08",
                    "C15.7233307,17.2400008",
                    "15.093337,17.32",
                    "14.36,17.32",
                    "C13.6133296,17.32",
                    "12.9333364,17.1933346",
                    "12.32,16.94",
                    "C11.7066636,16.6866654",
                    "11.1833355,16.3366689",
                    "10.75,15.89",
                    "C10.3166645,15.4433311",
                    "9.9833345,14.9066698",
                    "9.75,14.28",
                    "C9.5166655,13.6533302",
                    "9.4,12.9600038",
                    "9.4,12.2",
                    "C9.4,11.5066632",
                    "9.5166655,10.8633363",
                    "9.75,10.27",
                    "C9.9833345,9.6766637",
                    "10.3099979,9.1600022",
                    "10.73,8.72",
                    "C11.1500021,8.2799978",
                    "11.6499971,7.9333346",
                    "12.23,7.68",
                    "C12.8100029,7.4266654",
                    "13.4533298,7.3",
                    "14.16,7.3",
                    "C15.0133376,7.3",
                    "15.7366637,7.4566651",
                    "16.33,7.77",
                    "C16.9233363,8.0833349",
                    "17.3999982,8.4933308",
                    "17.76,9",
                    "C18.1200018,9.5066692",
                    "18.3799992,10.0799968",
                    "18.54,10.72",
                    "C18.7000008,11.3600032",
                    "18.78,12.01333",
                    "18.78,12.68",
                    "L18.78,13.02",
                    "L11.78,13.02",
                    "C11.78,13.3666684",
                    "11.8566659,13.6799986",
                    "12.01,13.96",
                    "C12.1633341,14.2400014",
                    "12.3633321,14.479999",
                    "12.61,14.68",
                    "C12.8566679,14.880001",
                    "13.1366651,15.0333328",
                    "13.45,15.14",
                    "C13.7633349,15.2466672",
                    "14.0733318,15.3",
                    "14.38,15.3",
                    "C14.8333356,15.3",
                    "15.2299983,15.2566671",
                    "15.57,15.17",
                    "C15.9100017,15.0833329",
                    "16.1999988,14.9833339",
                    "16.44,14.87",
                    "C16.6800012,14.7566661",
                    "16.8766659,14.6366673",
                    "17.03,14.51",
                    "C17.1833341,14.3833327",
                    "17.2999996,14.2800004",
                    "17.38,14.2",
                    "L18.66,15.64",
                    "Z",
                    "M16.36,11.32",
                    "C16.36,11.0799988",
                    "16.3133338,10.8400012",
                    "16.22,10.6",
                    "C16.1266662,10.3599988",
                    "15.9900009,10.1433343",
                    "15.81,9.95",
                    "C15.6299991,9.7566657",
                    "15.4000014,9.5966673",
                    "15.12,9.47",
                    "C14.8399986,9.3433327",
                    "14.5200018,9.28",
                    "14.16,9.28",
                    "C13.7733314,9.28",
                    "13.4300015,9.3433327",
                    "13.13,9.47",
                    "C12.8299985,9.5966673",
                    "12.580001,9.7566657",
                    "12.38,9.95",
                    "C12.179999,10.1433343",
                    "12.0266672,10.3599988",
                    "11.92,10.6",
                    "C11.8133328,10.8400012",
                    "11.76,11.0799988",
                    "11.76,11.32",
                    "L16.36,11.32",
                    "Z",
                    "M5.16,3.66",
                    "L7.52,3.66",
                    "L7.52,17",
                    "L5.16,17",
                    "L5.16,3.66",
                    "Z",
                ].join(" ")}
            />
        </g>
    </svg>
);
